import React, { useState } from 'react';

import styled from 'styled-components';
import Dropdown from '../inputs/dropdown';
import ProductDemoPanel from '../productDemoPanel';

const Container = styled.div`
    background: #fff;
    padding: 32px 64px;
    @media screen and (max-width: 767px) {
        padding: 16px 24px;
    }
`;

const Title = styled.h2`
    font-weight: normal;
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    user-select: none;
    margin-bottom: 26px;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
    }
`;

const Tabs = styled.ul`
    margin: 0 0 2rem 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

const Tab = styled.li`
    border: ${props =>
        props.active ? '1px solid #A68353' : '1px solid #C7C7C7'};
    color: ${props => (props.active ? 'white' : '#19171A')};
    background: ${props => (props.active ? '#A68353' : '#e0e0e0')};
    transition: all 300ms ease-in-out;
    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
`;

const TabButton = styled.button`
    font-family: 'Renogare-Regular';
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 8px 8px 8px;
    border: none;
    color: inherit;
    background: transparent;
    outline: none;
    cursor: pointer;
`;

const TabContent = styled.div``;

const MobileTabs = styled.div`
    display: none;
    @media screen and (max-width: 767px) {
        display: flex;
        margin-left: 24px;
        margin-right: 24px;
        width: calc(100% - 48px);
        flex-direction: column;
    }
`;

const TabbedPanel = ({ title, tabs }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const handleTabClick = tabId => {
        setCurrentTab(tabId);
    };
    const handleSelect = value => {
        setCurrentTab(tabs.findIndex(tab => tab.title === value));
    };
    return (
        <Container>
            <Title>{title}</Title>
            <Tabs>
                {tabs.map((tab, idx) => (
                    <Tab active={idx === currentTab} key={`demo-tab-${idx}`}>
                        <TabButton
                            onClick={() => {
                                handleTabClick(idx);
                            }}
                        >
                            {tab.title}
                        </TabButton>
                    </Tab>
                ))}
            </Tabs>
            <MobileTabs>
                <Dropdown
                    list={tabs.map(tab => tab.title)}
                    current={currentTab}
                    onChooseItem={handleSelect}
                    isLight
                />
            </MobileTabs>
            <TabContent>
                <ProductDemoPanel
                    {...tabs[currentTab]}
                    currentTab={currentTab}
                />
            </TabContent>
        </Container>
    );
};

export default TabbedPanel;
