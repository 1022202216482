import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components';
import GoldenButton from '../buttons/GoldenButton';
import VideoPlayer from '../videoPlayer';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: block;
    }
`;

const ChildPanel = styled.div`
    width: 45%;
    padding: 0 5% 5%;
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        margin-bottom: 2rem;
    }
`;

const Title = styled.h4`
    font-weight: normal;
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    margin-bottom: 26px;
    margin-top: 1rem;
    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        margin-bottom: 0px;
    }
`;

const Description = styled.div`
    font-family: 'Neuzeit Grotesk';
    margin-bottom: 3rem;
    & h3 {
        font-weight: normal;
        font-family: 'Renogare-Regular';
        font-size: 22px;
        line-height: 32px;
        letter-spacing: -0.5px;
        margin-bottom: 18px;
    }
    & ul {
        list-style-type: none;
        margin-left: 0;
        padding: 0;
    }
    & li {
        margin-bottom: 1rem;
    }
    & li::before {
        content: '• ';
        color: #bd975a;
    }
`;

const DemoLinks = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: 'Neuzeit Grotesk';
`;

const DemoLinkContainer = styled.li`
    display: inline-block;
    & + li:before {
        padding: 0 1rem;
        content: '/';
        color: #c3cad9;
    }
`;

const DemoLink = styled.a`
    cursor: pointer;
    color: ${props => (props.active ? '#996b3d' : '#5c5a5c')};
    text-decoration: ${props => (props.active ? 'none' : 'underline')};
`;

const CurrentDemo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
    width: 100%;
    margin-bottom: 2rem;
`;

const ProductVideo = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
`;

const ProductDemoPanel = ({
    title,
    description,
    button_text,
    button_url,
    product_demos,
    currentTab,
}) => {
    const [currentDemo, setCurrentDemo] = useState(0);
    const [demoHeight, setDemoHeight] = useState(0);
    const [demoWidth, setDemoWidth] = useState(0);
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            setDemoHeight(ref.current.clientHeight);
            setDemoWidth(ref.current.clientWidth);
        }
    }, []);

    useEffect(() => {
        setCurrentDemo(0);
    }, [currentTab]);

    const handleDemoLinkClick = (ev, demoId) => {
        ev.preventDefault();
        setCurrentDemo(demoId);
    };

    return (
        <Container>
            <ChildPanel>
                <Title>{title}</Title>
                <Description dangerouslySetInnerHTML={{__html: description}} />
                {button_text && (
                    <GoldenButton type="download" url={button_url}>
                        {button_text}
                    </GoldenButton>
                )}
            </ChildPanel>
            <ChildPanel>
                {product_demos[currentDemo] && (
                    <CurrentDemo ref={ref}>
                        {product_demos[currentDemo].video_url && (
                            <ProductVideo>
                                <VideoPlayer
                                    showControl={false}
                                    video_url={
                                        product_demos[currentDemo].video_url
                                    }
                                    ratioProps={{
                                        fluid: false,
                                        height:
                                            product_demos[currentDemo]
                                                .original_height,
                                        width:
                                            product_demos[currentDemo]
                                                .original_width,
                                        maxHeight: demoHeight,
                                        maxWidth: demoWidth,
                                    }}
                                    isStandard={false}
                                />
                            </ProductVideo>
                        )}
                    </CurrentDemo>
                )}
                <DemoLinks>
                    {product_demos.map((demo, idx) => (
                        <DemoLinkContainer key={`demo-panel-demo-${idx}`}>
                            <DemoLink
                                active={idx === currentDemo}
                                href="#"
                                onClick={ev => handleDemoLinkClick(ev, idx)}
                            >
                                {demo.title}
                            </DemoLink>
                        </DemoLinkContainer>
                    ))}
                </DemoLinks>
            </ChildPanel>
        </Container>
    );
};

export default ProductDemoPanel;
