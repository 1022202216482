import React from 'react';
import * as PropTypes from 'prop-types';
import { Player, ControlBar, BigPlayButton, LoadingSpinner } from 'video-react';
import 'video-react/dist/video-react.css';

import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    & > div {
        &.video-react {
            margin: 0 auto;
            position: relative;
            padding-top: ${props => (props.isStandard ? '56.35%' : '')};
        }
        .video-react-big-play-button {
            top: 50%;
            left: 50%;
            margin-top: -25px !important;
            margin-left: -25px !important;
            color: white;
            font-size: 36px !important;
            line-height: 1.3em;
            height: 50px;
            width: 50px;
            display: block;
            position: absolute;
            padding: 0;
            cursor: pointer;
            opacity: 1;
            border: 0.06666em solid #fff;
            background: linear-gradient(-30deg, #ccaa66 0%, #996b3d 100%);
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            -webkit-transition: all 0.4s;
            -moz-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
            font-family: 'video-react' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        .video-react-big-play-button:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
        }

        video {
            outline: none;
            user-select: none;
            background: #fff;
        }
    }
`;

const getVideoHeight = (maxHeight, maxWidth, height, width) => ({
    height: height > maxHeight ? maxHeight : height,
    width: width > maxWidth ? maxWidth : width,
});

const VideoPlayer = ({
    video_url,
    poster,
    isStandard,
    ratioProps,
    showControl,
}) => {
    let videoProps = {
        autoPlay: false,
        src: video_url,
        poster,
    };
    if (Object.keys(ratioProps).length) {
        const { width, height } = getVideoHeight(
            ratioProps.maxHeight,
            ratioProps.maxWidth,
            ratioProps.height,
            ratioProps.width
        );

        videoProps = { ...videoProps, fluid: ratioProps.fluid, height, width };
    }
    return (
        <Container isStandard={isStandard}>
            <Player {...videoProps}>
                <LoadingSpinner />
                <BigPlayButton position="center" />
                <ControlBar autoHide={true} disableCompletely={!showControl} />
            </Player>
        </Container>
    );
};

VideoPlayer.defaultProps = {
    ratioProps: {},
    isStandard: true,
    poster: '',
    showControl: true,
};

VideoPlayer.propTypes = {
    video_url: PropTypes.string.isRequired,
    poster: PropTypes.string,
    isStandard: PropTypes.bool,
    ratioProps: PropTypes.objectOf(PropTypes.any),
    showControl: PropTypes.bool,
};

export default VideoPlayer;
