import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Layout from '../layout/main';
import SEOComponent from '../components/seo';
import Hero4 from '../components/hero4';

import Article from '../components/article';
import TimelyAsset from '../components/timelyAsset';
import ProductsHighlight from '../components/productsHighlight';
import CTAComponent from '../components/cta';
import { Wave2, Wave1 } from '../components/wave';

import TabbedPanel from '../components/tabbedPanel';

const Intro = styled.div`
    background: #fafafa;
    padding-top: 8px;
    padding-bottom: 20px;
    padding-left: 64px;
    padding-right: 64px;
    width: calc(100% - 128px);
    @media screen and (max-width: 767px) {
        padding-top: 8px;
        padding-bottom: 24px;
        padding-left: 24px;
        padding-right: 24px;
        width: calc(100% - 48px);
    }
`;
const Main = styled.div`
    margin-top: 40px;
    @media screen and (max-width: 767px) {
        margin-top: 26px;
    }
`;
const Audience = styled.div`
    background: #fafafa;
    padding: 34px 64px 0;
    width: calc(100% - 128px);
    @media screen and (max-width: 767px) {
        width: calc(100% - 48px);
        padding: 34px 24px 0;
    }
`;
const AudienceHeader = styled.div`
    text-align: center;
    @media screen and (max-width: 1024px) {
        padding: 0;
    }
    @media screen and (max-width: 767px) {
        padding: 0;
    }
`;
const AudienceHeading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
    color: #19171a;
    width: 65%;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        width: 100%;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
        text-align: center;
    }
`;
const AudienceContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 21px;
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`;
const AudienceItem = styled.div`
    width: calc(33.33% - 80px);
    padding: 30px 40px;
    display: block;
    @media screen and (max-width: 767px) {
        width: 100%;
        padding: 24px 0;
    }
`;
const AudienceImg = styled.div`
    height: 240px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 767px) {
        height: 128px;
    }
`;
const AudienceTitle = styled.div`
    color: #19171a;
    font-family: 'Neuzeit Grotesk';
    font-size: 23px;
    line-height: 32px;
    letter-spacing: -0.5px;
    text-align: center;
    margin-top: 25px;
    @media screen and (max-width: 767px) {
        margin-top: 15px;
    }
`;

const SolutionImageHolder = styled.div`
    padding: 34px 64px 0;
    width: calc(100% - 228px);
    max-width: 70%;
    margin: 1rem auto 4rem;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

const SolutionImage = styled.img`
    width: 100%;
`;

const WaveHolder = styled.div`
    margin-top: -50px;
`;

export default function GoldStandardPage({ data }) {
    const {
        seo_content,
        hero,
        gold_standard_intro,
        book_now,
        further_details,
        media_partner,
        feature_grid,
        solution_image,
        product_demo_panels,
        timeline,
    } = data.butterPage;

    return (
        <Layout menu="FOR ADVERTISERS">
            <SEOComponent
                title="Gold Standard"
                image={seo_content.sharing_image}
                description={seo_content.seo_description}
            />
            <Hero4
                {...hero[0]}
                type={1}
                buttons={[
                    {
                        theme: 'golden',
                        title: 'Request Demo',
                        type: 'request-demo',
                    },
                    {
                        theme: 'hollow',
                        title: 'See Case Study',
                        type: 'url',
                        url: hero[0].case_study_url,
                    },
                ]}
            ></Hero4>
            <Intro>
                <Audience>
                    <AudienceHeader>
                        <AudienceHeading>
                            {gold_standard_intro.title}
                        </AudienceHeading>
                    </AudienceHeader>
                    <AudienceContent>
                        {gold_standard_intro.features.map(val => {
                            return (
                                <AudienceItem key={val.title}>
                                    <AudienceImg>
                                        <img
                                            src={val.image}
                                            height="100%"
                                            width="100%"
                                            alt={val.title}
                                            style={{
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </AudienceImg>
                                    <AudienceTitle>{val.title}</AudienceTitle>
                                </AudienceItem>
                            );
                        })}
                    </AudienceContent>
                </Audience>
            </Intro>
            <Main>
                <Article {...further_details} direction={'left'} spacing={1} />
                <WaveHolder>
                    <Wave2 />
                </WaveHolder>
                <Article {...media_partner} direction={'right'} spacing={1} />
                <Wave1 />
                <ProductsHighlight {...feature_grid} />
                <SolutionImageHolder>
                    <SolutionImage
                        src={solution_image}
                        alt="Audigents Gold Standard"
                    />
                </SolutionImageHolder>
                <div id="product-demos">
                    <TabbedPanel
                        title={product_demo_panels.title}
                        tabs={product_demo_panels.categories}
                    />
                </div>
                <TimelyAsset
                    {...timeline}
                    data={[
                        {
                            top: timeline.first_list_subtitle,
                            heading: timeline.first_list_title,
                            data: timeline.first_list,
                        },
                        {
                            top: timeline.second_list_subtitle,
                            heading: timeline.second_list_title,
                            data: timeline.second_list,
                        },
                    ]}
                />
            </Main>
            <CTAComponent {...book_now[0]} />
        </Layout>
    );
}

export const dataAgencyPageQuery = graphql`
    query {
        butterPage(slug: { eq: "gold-standard" }) {
            seo_content {
                seo_description
                sharing_image
            }
            hero {
                title
                image
                description
                desktop_image
                mobile_image
                mask_image
                case_study_url
            }
            gold_standard_intro {
                title
                features {
                    title
                    description
                    image
                }
            }
            further_details {
                title
                description
                image
            }
            media_partner {
                title
                description
                video_url
            }
            feature_grid {
                title
                description
                features {
                    title
                    description
                    image
                }
            }
            solution_image
            product_demo_panels {
                title
                categories {
                    title
                    description
                    button_text
                    button_url
                    product_demos {
                        title
                        video_url
                        original_height
                        original_width
                    }
                }
            }
            timeline {
                title
                description
                first_list_subtitle
                first_list_title
                first_list {
                    title
                }
                second_list_subtitle
                second_list_title
                second_list {
                    title
                }
            }
            book_now {
                button_title
                title
            }
        }
    }
`;
