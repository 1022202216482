import React from 'react';
import styled from 'styled-components';
import DownIcon from './../../assets/images/icon-gray-arrow-down.svg';
const Container = styled.div`
    margin: 32px 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        margin: 24px;
    }
`;

const Content = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`;
const Heading = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1px;
    color: white;
    text-align: left;
    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.92px;
    }
`;
const Desc = styled.div`
    margin-top: 34px;
    font-family: 'Neuzeit Grotesk';
    font-size: 17px;
    line-height: 24px;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
    @media screen and (max-width: 767px) {
        margin-top: 22px;
    }
`;
const Right = styled.div`
    width: 55%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
        width: 100%;
        flex-direction: column;
    }
`;
const Top = styled.div`
    font-family: 'Renogare-Regular';
    font-size: 12px;
    text-align: left;
    color: white;
    line-height: 24px;
    letter-spacing: normal;
    user-select: none;
    background: linear-gradient(0.25turn, #996b3d, #ccaa66, #ccaa66);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const TimelineHeading = styled.div`
    margin-top: 6px;
    color: white;
    font-size: 23px;
    line-height: 32px;
    text-align: left;
    font-family: 'Neuzeit Grotesk';
`;
const TimelineContent = styled.div`
    margin-top: 18px;
`;
const TimelineItem = styled.div`
    display: flex;
    padding: 8px 0;
`;
const TimelineIndex = styled.div`
    width: 32px;
    height: 32px;
    background: #464347;
    color: white;
    text-align: center;
    font-family: 'Renogare-Regular';
    font-size: 12px;
    line-height: 32px;
    border-radius: 16px;
    margin-right: 16px;
    margin-top: auto;
    margin-bottom: auto;
`;
const TimelineLabel = styled.div`
    font-size: 15px;
    line-height: 20px;
    font-family: 'Neuzeit Grotesk';
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const TimelineArrow = styled.div`
    padding-left: 4px;
    &:last-child {
        display: none;
    }
`;
const TimelineContainer = styled.div`
    width: 49%;
    margin-top: 0;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 30px;
    }
`;
const Timeline = ({ top, heading, data }) => {
    return (
        <TimelineContainer>
            <Top>{top}</Top>
            <TimelineHeading>{heading}</TimelineHeading>
            <TimelineContent>
                {data.map((item, index) => {
                    return (
                        <React.Fragment key={`key${index}`}>
                            <TimelineItem>
                                <TimelineIndex>{index + 1}</TimelineIndex>
                                <TimelineLabel>
                                    <div>{item.title}</div>
                                </TimelineLabel>
                            </TimelineItem>
                            <TimelineArrow>
                                <img src={DownIcon} alt="down icon" />
                            </TimelineArrow>
                        </React.Fragment>
                    );
                })}
            </TimelineContent>
        </TimelineContainer>
    );
};

export default function TimelyAsset({ title, description, data }) {
    return (
        <Container>
            <Content>
                <Heading>{title}</Heading>
                <Desc>{description}</Desc>
            </Content>
            <Right>
                {data.map(item => {
                    return <Timeline key={item.heading} {...item} />;
                })}
            </Right>
        </Container>
    );
}
